import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,{staticClass:"mb-20"},[(_vm.archived === false)?_c(VBtn,{staticClass:"mt-15",staticStyle:{"margin-right":"20px"},attrs:{"color":"vct-blue","small":"","to":"/employer/vetting/new"}},[_vm._v(" Invite Candidates ")]):_vm._e(),_c(VTextField,{staticClass:"pd-0 mt-0",attrs:{"append-icon":"mdi-database-search","color":"vct-blue","hide-details":"","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDivider,{staticClass:"mb-5"}),(_vm.candidates && _vm.items.length > 0)?_c(VDataIterator,{attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"default",fn:function(props){return _vm._l((props.items),function(item){return _c('div',{key:item.name},[_c('CandidateCard',{attrs:{"data":item,"search":_vm.search}})],1)})}}],null,false,1453278064)}):(_vm.candidates && _vm.items.length === 0)?_c('div',{staticClass:"pd-20"},[_vm._v(" No candidates to display ")]):_c(VProgressLinear,{attrs:{"color":"vct-blue","indeterminate":""}}),(_vm.candidates && _vm.items.length > 0)?_c(VCardActions,{staticClass:"caption"},[_vm._v(" Data is updated every hour. Vetting challenges are automatically failed and archived after 30 days of inactivity. ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }