<template>
  <v-app>
    <!-- Loading -->
    <v-progress-linear v-if="!loaded" color="vct-blue" indeterminate></v-progress-linear>

    <div v-else>
      <!-- application menu -->
      <Menu :opened="drawer" />

      <!-- application top bar -->
      <v-app-bar app color="vct-blue" dark dense>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </v-app-bar>

      <!-- application content -->
      <v-content>
        <slot />
      </v-content>
    </div>
  </v-app>
</template>

<script>
// mixins
import employer from '@/mixins/employer.js'
import ui from '@/mixins/ui.js'

// store
import employerStore from '@/stores/employer.js'

// components
import Menu from '@/components/employer/Menu'

// view
export default {
  mixins: [employer, ui],

  data () {
    return {
      drawer: true
    }
  },

  computed: {
    /*
     * Returns TRUE if the app is loaded
     */
    loaded () {
      return this.organisation && this.uiSetup
    }
  },

  beforeMount () {
    employerStore.dispatch('bindCandidates')
    employerStore.dispatch('bindOrganisationDoc')
    employerStore.dispatch('bindPackages')
  },

  components: {
    Menu
  }
}
</script>