<template>
  <div class="app-page mx-auto">
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step 
          :complete="step > 1"
          :editable="true"
          color="vct-blue"
          step="1"
          >

          Package Information
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step 
          :complete="step > 2"
          :editable="true"
          color="vct-blue"
          step="2"
          >

          Select Challenges
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- Package Information -->
        <v-stepper-content step="1">
          <v-text-field v-model="name"
            :rules="[rules.present, rules.maxlength(50)]"
            color="vct-blue"
            counter="50"
            filled
            placeholder="Package Name"
            required
          ></v-text-field>

          <v-textarea v-model="description"
            :rules="[rules.present, rules.maxlength(150)]"
            class="mt-5"
            color="vct-blue"
            counter="150"
            filled
            placeholder="Description"
            required
          >
          </v-textarea>

          <v-btn 
            @click="increment()"
            :disabled="!name || !description" 
            color="vct-blue" 
            small
            >
            Next
          </v-btn>
        </v-stepper-content>

        <!-- Select challenges -->
        <v-stepper-content step="2">
          <!-- List Exercises -->
          <v-expansion-panels accordion class="mb-20" flat focusable>
            <v-expansion-panel v-for="(category, index) in exerciseCategories" :key="index">
              <v-expansion-panel-header>
                {{ formatText(category) }} ({{ exercises.filter(e => e.groupUID === category).length }})
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row v-for="(exercise, index2) in exercises.filter(e => e.groupUID === category).sort(sortExercises)" 
                  :key="index2"
                  class="exercise-row"
                  >

                  <v-col cols="1">
                    <v-checkbox
                      @change="add(exercise.uuid)"
                      class="body-2 exercise-checkbox mr-2 mt-0 shrink"
                      color="vct-blue"
                      hide-details
                    ></v-checkbox>
                  </v-col>

                  <v-col class="body-2 exercise-label" cols="11">
                    {{ exercise.title }}
                    
                    <span class="caption">
                      ({{ exercise.difficulty }})
                    </span>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- display the challenges that the user has selected -->
          <div v-if="challenges.length > 0">
            <v-divider />

            <div class="fs-16 mt-20 mb-20">
              <b>
                Selected Exercises (max: 5):
              </b>

              <ul class="vct-blue-bullets mt-5">
                <li v-for="(uuid, index) in challenges" :key="index">
                  {{ exerciseTitle(uuid) }}
                </li>
              </ul>
            </div>
          </div>

          <!-- Discount code -->
          <div class="mt-20">
            <v-checkbox v-model="discount"
              color="green"
              label="As a thank-you to the candidates for participating in this technical evaluation, offer a $50 discount on MCSI courses."
            ></v-checkbox>
          </div>

          <!-- save button -->
          <v-btn 
            :disabled="!ready"
            @click="save()"
            color="vct-blue" 
            small
            >
            Save
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
// mixins
import firestore from '@/mixins/firestore.js'
import forms from '@/mixins/forms.js'
import stepper from '@/mixins/stepper.js'
import ui from '@/mixins/ui.js'
import user from '@/mixins/user.js'

// view
export default {
  mixins: [firestore, forms, stepper, ui, user],

  data () {
    return {
      challenges: [],
      description: null,
      discount: true,
      name: null,
      tab: null,

      headers: [
        {
          text: 'Challenge',
          value: 'title'
        },

        {
          text: 'Difficulty',
          value: 'difficulty'
        }
      ]
    }
  },

  computed: {
    /*
     * Returns TRUE if we have all the information needed to create a vetting package
     */
    ready () {
      return this.description
        && this.name
        && this.challenges.length > 0
    }
  },

  methods: {
    /*
     * Add or remove challenges
     */
    add (uuid) {
      if (this.challenges.length >= 5) {
        return false
      }

      if (!this.challenges.includes(uuid)) {
        return this.challenges.push(uuid)
      }

      this.challenges.splice(this.challenges.indexOf(uuid), 1)
    },

    /*
     * Save the vetting package
     */
    async save () {
      var doc = {
        'org-id': this.orgId,
        archived: false,
        description: this.description,
        discount: this.discount,
        exercises: this.challenges,
        label: this.name
      }

      if(await this.fsCreate('vetting-packages', doc)) {
        this.$router.push('/employer/vetting')
      }
    }
  }
}
</script>

<style>
.exercise-checkbox {
  margin: 0px;
  width: 30px;
}

.exercise-checkbox .v-messages {
  display: none;
}

.exercise-checkbox .v-input__slot {
  margin: 0 !important;
}

.exercise-label {
  margin-top: 6px;
}

.exercise-row .col {
  padding: 0 !important;
}

.exercise-row .col-1 {
  max-width: 5% !important;
}
</style>