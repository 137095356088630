export default {
  data () {
    return {
      step: 1
    }
  },

  methods: {
    increment () {
      this.step++
    }
  }
}