<template>
  <v-card>
    <!-- Username / Password Form -->
    <v-card-text>
      <v-form v-model="form" @keyup.native.enter="submit">
        <v-text-field v-model="email" 
          color="vct-blue"
          label="email@example.com" 
          name="email" 
          prepend-icon="mdi-email" 
          type="text"
          >
        </v-text-field>

        <v-text-field v-model="password"
          :rules="[rules.present, rules.length(8)]"
          color="vct-blue"
          id="password"
          label="Password" 
          name="password" 
          prepend-icon="mdi-lock" 
          type="password"
          >
        </v-text-field>
      </v-form>
    </v-card-text>

    <!-- Register button -->
    <v-card-actions class="register-buttons">
      <v-btn 
        :disabled="!valid"
        :loading="loading"
        @click="submit"
        color="vct-blue"
        style="width: 100%"
        >

        {{ buttonText }}
      </v-btn>
    </v-card-actions>

    <div class="authentication-divider">
      <span>
        OR
      </span>
    </div>

    <v-card-actions class="register-buttons">
      <v-btn 
        @click="signinWithGoogle"
        color="vct-blue"
        style="width: 100%"
        >
        Sign in with Google
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import firebase from 'firebase/compat/app'

// mixins
import forms from '@/mixins/forms.js'

// store
import ui from '@/stores/ui.js'

// view
export default {
  props: ['formType'],

  mixins: [forms],

  data () {
    return {
      form: false,
      email: null,
      loading: false,
      password: null
    }
  },

  computed: {
    /*
     * Returns TRUE if the form is properly filled, FALSE if not
     */
    valid () {
      return (this.form && this.email && this.password)
    },

    /*
     * Generic the button's text
     */
    buttonText () {
      if (this.formType === 'registration') {
        return 'Register'
      } else {
        return 'Login'
      }
    }
  },

  methods: {
    /*
     * Generic form submission function
     */
    async submit () {
      this.loading = true

      if (this.formType === 'registration') {
        await this.register()
      } else {
        await this.signin()
      }

      this.loading = false
    },

    /*
     * Register a new user account
     */
    async register () {
      await firebase.auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(async () => {
          await this.redirect()
        })
        .catch(error => {
          ui.commit('triggerErrorSnackbar', error)
        })
    },

    /*
     * Redirect dispatcher that implements the authentication / registration workflow
     */
    redirect () {
      this.$router.push('/employer/vetting')
    },

    /*
     * Authentication with username and password
     */
    async signin () {
      await firebase.auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(async () => {
          await this.redirect()
        })
        .catch(error => {
          ui.commit('triggerErrorSnackbar', error)
        })
    },

    /*
     * Authentication with Google SSO
     */
    async signinWithGoogle () {
      var provider = new firebase.auth.GoogleAuthProvider()

      provider.addScope('email')

      await firebase.auth().signInWithPopup(provider)
        .then(() => {
          this.redirect()
        })
        .catch(error => {
          ui.commit('triggerErrorSnackbar', error)
        }
      )
    }
  }
}
</script>

<style>

</style>