import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"authentication-page",attrs:{"fluid":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"xs12":"","sm8":"","md4":""}},[_c('img',{staticClass:"mb-50",staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/logo.png")}}),_c(VToolbar,{attrs:{"color":"vct-blue"}},[_c(VToolbarTitle,{staticClass:"centered card-header"},[_vm._v(" Authentication ")])],1),_c('AccountForm',{attrs:{"formType":"authentication"}}),_c('div',{staticClass:"account-links"},[_c('router-link',{attrs:{"to":"/registration"}},[_vm._v(" Create Account ")]),_c('span',[_vm._v(" | ")]),_c('router-link',{attrs:{"to":"/forgot-password"}},[_vm._v(" Forgot Password ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }