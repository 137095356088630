import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"authentication-page",attrs:{"fluid":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"xs12":"","sm8":"","md4":""}},[_c('img',{staticClass:"mb-50",staticStyle:{"width":"100%"},attrs:{"src":require("@/assets/logo.png")}}),_c(VToolbar,{attrs:{"color":"vct-blue"}},[_c(VToolbarTitle,{staticClass:"centered card-header"},[_vm._v(" Forgot Password ")])],1),_c(VCard,{attrs:{"tile":""}},[_c(VCardText,[_c('p',[_vm._v(" Please enter the email address you used to register: ")]),_c(VForm,{ref:"form"},[_c(VTextField,{attrs:{"rules":[_vm.rules.present, _vm.rules.maxlength(100), _vm.rules.email],"color":"vct-blue","filled":"","placeholder":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c(VBtn,{staticStyle:{"width":"100%"},attrs:{"loading":_vm.loading,"color":"vct-blue"},on:{"click":function($event){return _vm.reset()}}},[_vm._v(" Submit ")])],1)],1),_c('div',{staticClass:"account-links"},[_c('router-link',{attrs:{"to":"/registration"}},[_vm._v(" Create Account ")]),_c('span',[_vm._v(" | ")]),_c('router-link',{attrs:{"to":"/"}},[_vm._v(" Authenticate ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }