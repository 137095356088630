<template>
  <v-content>
    <slot/>
  </v-content>
</template>

<script>
export default {

}
</script>

<style>

</style>
