<template>
  <v-stepper class="progress-stepper">
    <v-stepper-header>
      <v-stepper-step 
        :complete="data.process['opened-email'] !== false"
        :rules="[() => data.process['opened-email'] !== false]"
        color="vct-blue"
        step="1">

        Opened Email
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step 
        :complete="data.process['accepted-challenge'] !== false" 
        :rules="[() => data.process['accepted-challenge'] !== false]"
        color="vct-blue"
        step="2">

        Accepted Challenge
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step 
        :complete="data.process['submitted-exercises'] !== false"
        :rules="[() => data.process['submitted-exercises'] !== false]"
        color="vct-blue"
        step="3">

        Submitted Exercise(s)
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  props: ['data']
}
</script>

<style>

</style>