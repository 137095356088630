<template>
  <v-container fluid class="authentication-page">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <!-- logo -->
        <img class="mb-50" src="@/assets/logo.png" style="width: 100%" />

        <!-- View Header -->
        <v-toolbar color="vct-blue">
          <v-toolbar-title class="centered card-header">
            Forgot Password
          </v-toolbar-title>
        </v-toolbar>

        <!-- Card -->
        <v-card tile>
          <v-card-text>
            <p>
              Please enter the email address you used to register:
            </p>

            <v-form ref="form">
              <v-text-field v-model="email"
                :rules="[rules.present, rules.maxlength(100), rules.email]"
                color="vct-blue"
                filled
                placeholder="Email"
              ></v-text-field>
            </v-form>

            <v-btn @click="reset()" :loading="loading" color="vct-blue" style="width:100%">
              Submit
            </v-btn>
          </v-card-text>
        </v-card>

        <!-- Register / Authenticate -->
        <div class="account-links">
          <router-link to="/registration">
            Create Account
          </router-link>
          <span> | </span>
          <router-link to="/">
            Authenticate
          </router-link>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import firebase from 'firebase/compat/app'

// mixins
import forms from '@/mixins/forms.js'
import ui from '@/mixins/ui.js'

// view
export default {
  mixins: [forms, ui],

  data () {
    return {
      email: null,
      loading: false
    }
  },

  methods: {
    /*
     *
     */
    async reset () {
      if (this.$refs.form.validate()) {
        this.loading = true

        await firebase.auth().sendPasswordResetEmail(this.email)
          .then(() => {
            this.throwSuccess('A password reset email has been sent to you!')
          })
          .catch(error => {
            this.throwError(error)
          })
        
        this.loading = false
      }
    }
  }
}
</script>

<style>

</style>