import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"hover":true,"color":"blue lighten-5"}},[_c(VCardTitle,{staticClass:"bold fs-16"},[_vm._v(" "+_vm._s(_vm.data.name)+" "),_c(VSpacer),(_vm.isOrgAdmin)?_c(VBtn,{attrs:{"loading":_vm.loading,"color":"orange","small":"","text":""},on:{"click":function($event){return _vm.deleteRep()}}},[_vm._v(" Remove ")]):_vm._e()],1),_c(VDivider),_c(VCardText,{staticClass:"fs-16"},[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{staticClass:"pd-10",attrs:{"xs12":"","sm6":""}},[_c('div',{staticClass:"mb-5"},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-email")]),_vm._v(" "+_vm._s(_vm.data.email)+" ")],1),_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-phone")]),_vm._v(" "+_vm._s(_vm.data.phone)+" ")],1),_c(VFlex,{staticClass:"pd-10",attrs:{"xs12":"","sm6":""}},[_c('div',{staticClass:"mb-5"},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-home-city")]),_vm._v(" "+_vm._s(_vm.data.city)+" ")],1),_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-city")]),_vm._v(" "+_vm._s(_vm.data.country)+" ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }