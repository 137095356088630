import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('div',{staticClass:"pd-10"},[_c(VCard,{staticClass:"blue lighten-5",attrs:{"hover":true,"to":`/employer/vetting/candidate/${_vm.data.id}`}},[_c(VCardTitle,[_c(VAvatar,{attrs:{"color":_vm.statusColour,"size":"15px"}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-account-circle")])],1),_c('span',{staticClass:"title font-weight-light",staticStyle:{"padding-left":"10px"}},[_vm._v(" "+_vm._s(_vm.data.email)+" ")]),_c(VSpacer),_c('span',{staticClass:"font-weight-bold title uppercase",style:(`color: ${_vm.statusColour}`)},[_vm._v(" "+_vm._s(_vm.statusText)+" ")])],1),_c(VCardText,[_c('div',{staticClass:"fs-16 mt-5"},[_c('b',[_vm._v("Vetting Package:")]),_vm._v(" "+_vm._s(_vm._package.label)),_c('br')]),_c('div',{staticClass:"fs-16 mt-5"},[_c('b',[_vm._v("Invited:")]),_vm._v(" "+_vm._s(_vm.data.timestamp.toDate().toLocaleString())+" ")]),_c(VChipGroup,{staticClass:"mt-20",attrs:{"active-class":"deep-purple accent-4 white--text","column":""}},_vm._l((_vm.categories),function(skill,index){return _c(VChip,{key:index,staticClass:"fs-12"},[_vm._v(" "+_vm._s(_vm.formatText(skill))+" ")])}),1)],1),_c(VCardActions,{staticStyle:{"padding-top":"0 !important"}},[_c('CandidateStepper',{attrs:{"data":_vm.data}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }