export default {
  methods : {
    /*
     * Returns TRUE if the array has no NULL values
     */
    objHasNoNull (obj) {
      if (!obj) {
        return false
      }

      var keys = Object.keys(obj)

      for(var i in keys) {
        var k = keys[i]

        if (obj[k] === null) {
          return false
        }
      }

      return true
    },

    /*
     * Generic function to generic unique arrays
     *
     * https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
     */
    onlyUnique (value, index, self) { 
      return self.indexOf(value) === index;
    },

    /*
     * Open URL is a new tab
     */
    openWindow (url) {
      return window.open(url, '_blank')
    },

    /*
     * 
     */
    newFsObject (original, newValues) {
      var doc = {}

      Object.keys(original).forEach(k => {
        if (Object.keys(newValues).includes(k)) {
          doc[k] = newValues[k]
        }
      })

      return doc
    },

    /*
     * Safely copies the keys from one object into another object
     * only if the key of the first object has the key of the second
     * object 
     */
    safeCopyObjects (from, to) {
      Object.keys(from).forEach(k => {
        if (Object.keys(to).includes(k)) {
          to[k] = from[k]
        }
      })
    }
  }
}