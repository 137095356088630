<template>
  <v-card>
    <v-card-title class="mb-20">
      <v-btn v-if="archived === false"
        class="mt-15" 
        color="vct-blue" 
        small 
        style="margin-right: 20px;"
        to="/employer/vetting/new"
        >
        Invite Candidates
      </v-btn>

      <v-text-field v-model="search"
        append-icon="mdi-database-search"
        class="pd-0 mt-0"
        color="vct-blue"
        hide-details
        label="Search"
        single-line
      ></v-text-field>
    </v-card-title>

    <v-divider class="mb-5" />

    <!-- Show the list of individuals being vetted -->
    <v-data-iterator v-if="candidates && items.length > 0" :items="items">
      <!-- Talent Card Component -->
      <template v-slot:default="props">           
        <div v-for="item in props.items" :key="item.name">
          <CandidateCard :data="item" :search="search" />
        </div>
      </template>
    </v-data-iterator>

    <div v-else-if="candidates && items.length === 0" class="pd-20">
      No candidates to display
    </div>

    <v-progress-linear v-else color="vct-blue" indeterminate></v-progress-linear>

    <!-- Footer -->
    <v-card-actions v-if="candidates && items.length > 0" class="caption">
      Data is updated every hour. Vetting challenges are automatically failed and archived after 30 days of inactivity.
    </v-card-actions>
  </v-card>
</template>

<script>
import CandidateCard from '@/components/employer/vetting/CandidateCard'
import employer from '@/mixins/employer.js'

export default {
  mixins: [employer],

  props: ['archived', 'successful'],

  data () {
    return {
      search: ''
    }
  },

  computed: {
    items () {
      if (!this.successful) {
        return this.candidates.filter(c => c.archived === this.archived)
      } else {
        return this.candidates.filter(c => c.pass === true)
      }
    }
  },

  components: {
    CandidateCard
  }
}
</script>

<style>

</style>
