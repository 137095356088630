export default {
  data () {
    return {
      rules: {
        /*
         *
         */
        length: len => v => (v || '').length >= len || `Invalid character length, required ${len}`,

        /*
         *
         */
        maxlength: len => v => (v || '').length <= len || 'Field is invalid',

        /*
         *
         */
        present: v => (v || '').length >= 1 || 'Field is required',
        
        /*
         *
         */
        // email: v => /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
        email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        
        /*
         *
         */
        phone: v => /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,10}$/.test(v) || 'Phone number is invalid',
        
        /*
         *
         */
        noSpecialCharacters: v => /^[0-9a-zA-Z\b]+$/.test(v) || 'Only letters and numbers allowed',

        /*
         *
         */
        noSpecialCharactersExceptSpace: v => /^[0-9a-zA-Z \b]+$/.test(v) || 'Field cannot contain special characters',

        /*
         *
         */
        url: v=> /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i.test(v) || 'Invalid URL',

        /*
         *
         */
        uuid: v => /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i.test(v) || 'Invalid UUID'
      }
    }
  }
}
