import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardText,[_c(VForm,{nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)}},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c(VTextField,{attrs:{"color":"vct-blue","label":"email@example.com","name":"email","prepend-icon":"mdi-email","type":"text"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"rules":[_vm.rules.present, _vm.rules.length(8)],"color":"vct-blue","id":"password","label":"Password","name":"password","prepend-icon":"mdi-lock","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VCardActions,{staticClass:"register-buttons"},[_c(VBtn,{staticStyle:{"width":"100%"},attrs:{"disabled":!_vm.valid,"loading":_vm.loading,"color":"vct-blue"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])],1),_c('div',{staticClass:"authentication-divider"},[_c('span',[_vm._v(" OR ")])]),_c(VCardActions,{staticClass:"register-buttons"},[_c(VBtn,{staticStyle:{"width":"100%"},attrs:{"color":"vct-blue"},on:{"click":_vm.signinWithGoogle}},[_vm._v(" Sign in with Google ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }