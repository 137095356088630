/* the vue router paths for public facing pages */

import Authentication from '@/views/public/Authentication'
import ForgotPassword from '@/views/public/ForgotPassword'
import InviteCandidate from '@/views/public/vetting/InviteCandidate'
import Register from '@/views/public/Register'

export default [
  {
    path: '/',
    component: Authentication,
    
    meta: {
      layout: 'plain-layout',
      public: true
    }
  },

  {
    path: '/forgot-password',
    component: ForgotPassword,
    
    meta: {
      layout: 'plain-layout',
      public: true
    }
  },

  {
    path: '/invite-candidate/:candidateId',
    component: InviteCandidate,
    
    meta: {
      layout: 'plain-layout',
      public: true
    }
  },

  {
    path: '/invite-candidate/:candidateId/:accepted',
    component: InviteCandidate,
    
    meta: {
      layout: 'plain-layout',
      public: true
    }
  },

  {
    path: '/registration',
    component: Register,
    
    meta: {
      layout: 'plain-layout',
      public: true
    }
  }
]