import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VContent } from 'vuetify/lib/components/VContent';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[(!_vm.loaded)?_c(VProgressLinear,{attrs:{"color":"vct-blue","indeterminate":""}}):_c('div',[_c('Menu',{attrs:{"opened":_vm.drawer}}),_c(VAppBar,{attrs:{"app":"","color":"vct-blue","dark":"","dense":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}})],1),_c(VContent,[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }