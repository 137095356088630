<template>
  <div class="mx-auto width-840">
    <v-card style="margin-top: 10px;">
      <v-card-title>
        <div class="title font-weight-light" style="width: 100%;">
          Global Audit Logs
        </div>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :loading="!items || items.length === 0"
      >
        <template v-slot:item.timestamp="{ item }">
          {{ item.timestamp.toDate().toLocaleString() }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import 'firebase/compat/firestore'
import firebase from 'firebase/compat/app'
import user from '@/mixins/user.js'

export default {
  mixins: [user],

  data () {
    return {
      headers: [
        { text: 'Event', value: 'event' },
        { text: 'Timestamp', value: 'timestamp' }
      ],

      items: null
    }
  },

  firestore () {
    return {
      items: firebase.firestore()
        .collection('audit-logs')
        .where('organisation', '==', this.orgId)
        .orderBy('timestamp', 'desc')
        .limit(200)
    }
  }
}
</script>