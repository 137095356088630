import 'firebase/compat/functions'
import firebase from 'firebase/compat/app'

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    exercises: null,
    menu: true,
    snackbar: false,
    snackbarText: null,
    snackbarColor: '',
    snackbarTimeout: 6000
  },

  getters: {
    exercises: state => {
      return state.exercises
    },

    menu: state => {
      return state.menu
    },

    snackbarStatus: state => {
      return state.snackbar
    },

    snackbarText: state => {
      return state.snackbarText
    },

    snackbarColor: state => {
      return state.snackbarColor
    },

    snackbarTimeout: state => {
      return state.snackbarTimeout
    }
  },

  actions: {
    /*
     * Retrieve the list of exercises from the backend
     */
    async retrieveExercises ({commit}) {
      var call = firebase.functions().httpsCallable('jsonGetExercises?timestamp=' + new Date().getTime())

      await call()
        .then(async (response) => {
          if (response.data) {
            await commit('setExercises', response.data || [])
          }
        })
        .catch(async (error) => {
          window.console.error(error)
          await commit('triggerErrorSnackbar', error)
        })
    }
  },

  mutations: {
    /*
     *
     */
    setExercises (state, exercises) {
      state.exercises = exercises
    },

    /*
     *
     */
    closeSnackbar (state) {
      state.snackbar = false
    },

    /*
     *
     */
    toggleMenu (state) {
      state.menu = !state.menu
    },
    
    /*
     *
     */
    triggerSuccessSnackbar (state, text) {
      state.snackbar = true
      state.snackbarColor = 'vct-blue'

      if (text['data'] && text['data']['message']) {
        state.snackbarText = text['data']['message']
      } else if (text['data'] && typeof text['data'] === 'string') {
        state.snackbarText = text['data']
      } else {
        state.snackbarText = text
      }

      setTimeout(() => { state.snackbar = false }, state.snackbarTimeout)
    },

    /*
     *
     */
    triggerErrorSnackbar (state, error) {
      state.snackbar = true
      state.snackbarColor = 'error'
      state.snackbarText = error['message'] || error
      
      setTimeout(() => { state.snackbar = false }, state.snackbarTimeout)
    }
  }
})