import user from '@/stores/user.js'
import utils from '@/mixins/utils.js'

export default {
  mixins: [utils],

  computed: {
    /*
     * Returns TRUE if the account is setup
     */
    accountSetup () {
      return this.objHasNoNull(this.settings)
    },

    // returns the user's email
    email () {
      return user.getters.email
    },

    // returns TRUE/FALSE on whether the user has validated their email
    emailVerified () {
      return user.getters.emailVerified
    },

    // returns TRUE if the user is can send vetting applications for free
    freeVettingPermissions () {
      return (this.roles && this.roles.includes('free-vetting-permissions'))
    },

    // returns TRUE if the user is an administrator
    isAdmin () {
      return (this.roles && this.roles.includes('administrator'))
    },

    // returns TRUE if the user is a recruiter
    isRecruiter () {
      return this.roles.includes('recruiter')
    },

    // returns the user's `org-id`
    orgId () {
      if (this.settings) {
        return this.settings['org-id'] || null
      }

      return null
    },

    // returns the user's roles
    roles () {
      if (this.settings) {
        return this.settings['roles'] || []
      }

      return null
    },

    // returns the user's `settings` firestore document
    settings () {
      return user.getters.settings
    },
    
    // returns the user's `uid`
    uid () {
      return user.getters.uid
    }
  }
}