import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{attrs:{"value":this.opened,"width":220,"app":""}},[_c('img',{staticClass:"sidenav-logo",attrs:{"src":require("@/assets/logo-sidenav.png")}}),_c(VDivider),_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"color":"vct-blue"}},[_c(VListItem,{attrs:{"to":"/employer/vetting"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-playlist-check")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Vetting ")])],1)],1)],1),_c(VSubheader,[_vm._v(" Account ")]),_c(VListItemGroup,{attrs:{"color":"vct-blue"}},[_c(VListItem,{attrs:{"to":"/employer/user-profile"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account-circle")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Account Information ")])],1)],1),_c(VListItem,{attrs:{"to":"/employer/representatives"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account-multiple")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Representatives ")])],1)],1),_c(VListItem,{attrs:{"to":"/employer/audit-logs"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-file-settings")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Audit Logs ")])],1)],1)],1),_c(VSubheader,[_vm._v(" Actions ")]),_c(VListItemGroup,{attrs:{"color":"vct-blue"}},[_c(VListItem,{on:{"click":function($event){return _vm.logout()}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account-circle")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Logout ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }