<template>
  <v-container fluid class="authentication-page">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <!-- logo -->
        <img class="mb-50" src="@/assets/logo.png" style="width: 100%" />

        <!-- View Header -->
        <v-toolbar color="vct-blue">
          <v-toolbar-title class="centered card-header">
            Authentication
          </v-toolbar-title>
        </v-toolbar>

        <AccountForm formType="authentication" />

        <!-- Create Account / Forgot Password -->
        <div class="account-links">
          <router-link to="/registration">
            Create Account
          </router-link>
          <span> | </span>
          <router-link to="/forgot-password">
            Forgot Password
          </router-link>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AccountForm from '@/components/public/AccountForm'

export default {
  components: {
    AccountForm
  }
}
</script>

<style>

</style>
