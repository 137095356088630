<template>
  <v-card>
    <!-- title section -->
    <v-card-title class="mb-20" v-if="archived === false">
      <v-btn 
        class="mt-15" 
        color="vct-blue" 
        small 
        style="margin-right: 20px;"
        to="/employer/vetting/new-package"
        >

        New Package
      </v-btn>

      <v-text-field v-model="search"
        append-icon="mdi-database-search"
        color="vct-blue"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-divider class="mb-5" />

    <!-- Iterate over Packages -->
    <v-data-iterator v-if="packages && items.length > 0" :items="items">
      <!-- Package Card Component -->
      <template v-slot:default="props">           
        <div v-for="(item, index) in props.items" :key="index">
          <Package :data="item" :search="search" />
        </div>
      </template>
    </v-data-iterator>

    <div v-else-if="packages && items.length === 0" class="pd-20">
      No packages to display
    </div>

    <v-progress-linear v-else color="vct-blue" indeterminate></v-progress-linear>
  </v-card>
</template>

<script>
// mixins
import employer from '@/mixins/employer.js'
import user from '@/mixins/user.js'

// components
import Package from '@/components/employer/vetting/Package'

export default {
  props: ['archived'],

  mixins: [employer, user],

  data () {
    return {
      search: ''
    }
  },

  computed: {
    items () {
      var res = this.packages.filter(p => p.archived === this.archived)

      // filter by search
      if (this.search && this.search.length) {
        res = res.filter(p => p && p.label && p.label.toLowerCase().includes(this.search.toLowerCase()))
      }

      return res
    }
  },

  components: {
    Package
  }
}
</script>