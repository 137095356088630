import firebase from 'firebase/compat/app'
import ui from '@/stores/ui.js'

export default {
  data () {
    return {
      db: firebase.firestore(),
      loading: false
    }
  },

  methods: {
    /*
     * Create
     */
    async fsCreate (collection, doc) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .add(doc)
        .then((docRef) => {
          ui.commit('triggerSuccessSnackbar', 'Created!')

          return docRef
        })
        .catch((error) => {
          window.console.error(error)
          ui.commit('triggerErrorSnackbar', error)

          return false
        })

      this.loading = false

      return res
    },

    /*
     * Delete
     */
    async fsDelete (collection, docId) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .doc(docId)
        .delete()
        .then(() => {
          ui.commit('triggerSuccessSnackbar', 'Deleted!')

          return true
        })
        .catch((error) => {
          window.console.error(error)
          ui.commit('triggerErrorSnackbar', error)

          return false
        })

      this.loading = false

      return res
    },

    /*
     * Get
     */
    async fsGet (collection, docId) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .doc(docId)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            ui.commit('triggerErrorSnackbar', 'The document requested does not exist')

            return null
          }

          return doc.data()
        })
        .catch((error) => {
          window.console.error(error)
          ui.commit('triggerErrorSnackbar', error)

          return null
        })
      
      this.loading = false

      return res
    },

    /*
     * Set
     */
    async fsSet (collection, docId, doc) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .doc(docId)
        .set(doc)
        .then(() => {
          ui.commit('triggerSuccessSnackbar', 'Created!')

          return true
        })
        .catch((error) => {
          window.console.error(error)
          ui.commit('triggerErrorSnackbar', error)

          return false
        })

      this.loading = false

      return res
    },

    /*
     * Update
     */
    async fsUpdate (collection, docId, doc) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .doc(docId)
        .update(doc)
        .then(() => {
          ui.commit('triggerSuccessSnackbar', 'Updated!')

          return true
        })
        .catch((error) => {
          window.console.error(error)
          ui.commit('triggerErrorSnackbar', error)

          return false
        })

      this.loading = false

      return res
    }
  }
}