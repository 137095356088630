<template>
  <div v-if="loaded" class="pd-10">       
    <v-card :hover="true" class="blue lighten-5">
      <v-card-title>
        <div class="headline">
          {{ data.label }}
        </div>

        <v-spacer />

        <v-btn v-if="!noaction"
          @click="archive(data.id, data.archived)"
          color="blue"
          small
          text
          >

          {{ (!data.archived) ? 'Archive' : 'Take' }}
        </v-btn>
      </v-card-title>

      <!-- Description -->
      <v-card-text class="black--text">
        {{ data.description || '' }}
        
        <!-- Exercise List -->
        <ul class="vct-blue-bullets mt-3">
          <li v-for="(uuid, index) in data.exercises" :key="index">
            {{ exerciseTitle(uuid) }}
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// mixins
import firestore from '@/mixins/firestore.js'
import ui from '@/mixins/ui.js'

export default {
  props: ['data', 'noaction', 'search'],

  mixins: [firestore, ui],

  computed: {
    loaded () {
      return this.data.label.toLowerCase().includes(this.search.toLowerCase())
    }
  },

  methods: {
    archive (uid, archived) {
      this.fsUpdate('vetting-packages', uid, { archived: !archived })
    }
  }
}
</script>

<style>
.exercise-list {
  margin-top: 10px;
}

.exercise-check {
  margin: 0px;
}

.exercise-check .v-messages {
  display: none;
}

.exercise-check .v-input__slot {
  margin: 0 !important;
}

.exercise-check .v-label {
  font-size: 12px;
}

.exercise-check .v-input--selection-controls__input {
  font-size: 12px;
  height: 10px;
  width: 24px;
}
</style>