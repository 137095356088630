import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"authentication-page",attrs:{"fluid":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"xs12":"","sm8":"","md4":""}},[_c(VToolbar,{attrs:{"color":"vct-blue"}},[_c(VToolbarTitle,{staticClass:"centered card-header"},[_vm._v(" Please Verify Your Email ")])],1),_c(VCard,{attrs:{"tile":""}},[_c(VCardText,[_c('p',[_vm._v(" For security reasons, please verify your email address: ")]),_c(VTextField,{attrs:{"disabled":true,"value":_vm.email,"color":"vct-blue","filled":""}}),_c(VBtn,{staticStyle:{"width":"100%"},attrs:{"loading":_vm.loading,"color":"vct-blue"},on:{"click":function($event){return _vm.send()}}},[_vm._v(" Send Confirmation Email ")])],1)],1),_c('div',{staticClass:"account-links"},[_c('a',{on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Try another account ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }