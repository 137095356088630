<template>
  <div class="app-page mx-auto">
    <div v-if="data && items">
      <div class="caption mb-3">
        <router-link class="no-decoration" to="/employer/vetting">
          Candidates
        </router-link> > Candidate Id: {{ docId }}
      </div>

      <v-card class="mb-20">
        <!-- Title -->
        <v-card-title>
          <v-avatar :color="statusColour" size="15px">
            <v-icon dark>mdi-account-circle</v-icon>
          </v-avatar>

          <span class="title font-weight-light" style="padding-left:10px;">
            {{ data.email }}
          </span>
          
          <v-spacer></v-spacer>

          <span class="font-weight-bold title uppercase"  :style="`color: ${statusColour}`">
            {{ statusText }}
          </span>
        </v-card-title>

        <v-divider />

        <!-- Stepper -->
        <v-card-text class="blue lighten-5">
          <CandidateStepper :data="data" />
        </v-card-text>

        <v-divider />

        <!-- Exercises -->
        <v-data-table
          :headers="headers"
          :items="items.exercises"
          :items-per-page="-1"
          disable-sort
          hide-default-footer
          >
          <!-- `exercise` column -->
          <template v-slot:item.title="{ item }">
            {{ item.title }}
            
            <a v-if="item.youtube" class="no-decoration" :href="item.youtube" target="_blank">
              <v-icon color="vct-blue" small>mdi-alpha-y-box-outline</v-icon>
            </a>

            <a v-if="item.url" class="no-decoration" :href="item.url" target="_blank">
              <v-icon color="vct-blue" small>mdi-download</v-icon>
            </a>
          </template>

          <!-- `pass` column -->
          <template v-slot:item.category="{ item }">
            {{ formatText(item.category) }}
          </template>

          <!-- `pass` column -->
          <template v-slot:item.pass="{ item }">
            <v-chip v-if="item['not-submitted']"
              class="fs-12"
              color="gray"
              dark
              small
              >
              Not submitted
            </v-chip>

            <v-chip v-else-if="item['awaiting-review']"
              class="fs-12"
              color="orange"
              dark
              small
              >
              Awaiting review
            </v-chip>

            <v-chip v-else
              :color="(item.pass) ? 'vct-blue' : 'red'" 
              class="fs-12"
              dark
              small
              >
              {{ formatText(item.pass.toString()) }}
            </v-chip>
          </template>
        </v-data-table>

        <v-card-text v-if="!data['process']['accepted-challenge']" class="caption">
          You cannot download the candidate's submissions until they have accepted the challenge.
        </v-card-text>

        <v-divider />

        <!-- Invitation link -->
        <v-card-text class="caption blue lighten-5">
          <b>If the candidate hasn't received the notification email then send them this link:</b>

          <v-text-field v-model="url"
            class="caption pd-0 mt-0"
            color="vct-blue"
            hide-details
            single-line
          ></v-text-field>
        </v-card-text>
        
        <v-divider />

        <!-- Logs -->
        <v-card-actions class="blue lighten-5 caption" style="display: block;">
          <b>Logs</b>

          <div>
            You sent this vetting challenge on: {{ data.timestamp.toDate().toLocaleString() }}
          </div>

          <div v-if="items['lastSignInTime']">
            We last saw this candidate on: {{ items['lastSignInTime'] }}
          </div>

          <div v-if="data['process']['opened-email']">
            The candidate last opened the invitation email on: {{ data['process']['opened-email'].toDate().toLocaleString() }}
          </div>

          <div v-if="data['process']['accepted-challenge']">
            The candidate accepted the challenge on: {{ data['process']['accepted-challenge'].toDate().toLocaleString() }}
          </div>
        </v-card-actions>

        <!-- Fail/Pass buttons -->
        <v-card-actions v-if="!data.pass && !data.fail" class="caption">
          <v-spacer />

          <v-btn 
            @click="failDialog = true"
            color="red"
            text
            small
            >

            Reject Candidate
          </v-btn>

          <v-btn 
            @click="passDialog = true"
            color="vct-blue"
            text
            small
            >

            Accept Candidate
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- Loading bar -->
    <v-progress-linear v-else color="vct-blue" indeterminate></v-progress-linear>

    <!-- Fail Dialog -->
    <v-dialog v-model="failDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Reject Candidate
        </v-card-title>

        <v-card-text>
          <p class="mt-15">
            Please confirm that you want to reject this candidate's application.
          </p>
          <p>
            The candidate will receive an email notification with you on copy.
          </p>
          <p>
            To thank them for applying to join your organisation, Vetted Cyber Talent will give them a $50 discount voucher to purchase online training offered by Mossé Cyber Security Institute (MCSI) via its <a href="https://platform.mosse-institute.com/" target="_blank">Online Learning Platform</a> (OLP).
          </p>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="failDialog = false" :loading="loading" text small>Cancel</v-btn>
          <v-btn @click="fail()" :loading="loading" color="red" text small>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Pass Dialog -->
    <v-dialog v-model="passDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Accept Candidate
        </v-card-title>

        <v-card-text>
          <p class="mt-15">
            Please confirm that the candidate has passed the technical assessment.
          </p>
          <p>
            They will receive an email notification informing them of the good news!
          </p>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn @click="passDialog = false" :loading="loading" text small>
            Cancel
          </v-btn>

          <v-btn @click="pass()" :loading="loading" color="vct-blue" text small>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import 'firebase/compat/functions'
import firebase from 'firebase/compat/app'

// mixins
import employer from '@/mixins/employer.js'
import firestore from '@/mixins/firestore.js'
import portal from '@/mixins/portal.js'
import uiMixin from '@/mixins/ui.js'
import vetting from '@/mixins/vetting.js'

// store
import ui from '@/stores/ui.js'

// view
export default {
  mixins: [employer, firestore, portal, uiMixin, vetting],

  data () {
    return {
      failDialog: false,
      passDialog: false,

      items: null,

      headers: [
        {
          text: 'Exercise',
          value: 'title'
        },

        {
          text: 'Category',
          value: 'category'
        },

        {
          text: 'Pass?',
          value: 'pass'
        }
      ]
    }
  },

  computed: {
    data () {
      return this.candidates.filter(c => c.id === this.docId)[0] || null
    },

    url () {
      return `https://portal.vetted-cyber-talent.com/#/invite-candidate/${this.docId}`
    }
  },

  methods: {
    /*
     * Fail the candidate
     */
    async fail () {
      var doc = {
        archived: true,
        fail: true
      }

      if (await this.fsUpdate('candidates', this.docId, doc)) {
        this.failDialog = false
      }
    },

    /*
     * Pass the candidate
     */
    async pass () {
      var doc = {
        archived: true,
        pass: true
      }

      if (await this.fsUpdate('candidates', this.docId, doc)) {
        this.passDialog = false
      }
    },

    /*
     * Retrieves the status of the exercises from MCSI's Online Learning Platform
     */
    async retrieveExercises () {
      var call = firebase.functions().httpsCallable('jsonGetCandidateExercises?timestamp=' + new Date().getTime())

      this.items = await call({candidateId: this.docId})
        .then(async (response) => {
          return response.data || null
        })
        .catch(async (error) => {
          window.console.error(error)
          await ui.commit('triggerErrorSnackbar', error)
        })
    }
  },

  mounted () {
    this.retrieveExercises()
  }
}
</script>

<style>

</style>