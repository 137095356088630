<template>
  <div class="app-page elevation-1 mx-auto">
    <v-progress-linear v-if="!representatives" color="vct-blue" indeterminate></v-progress-linear>

    <v-tabs v-else color="vct-blue" slider-color="vct-blue">
      <v-tab href="#list">
        <v-icon left>mdi-account-multiple</v-icon> Representatives
      </v-tab>

      <v-tab v-if="isOrgAdmin" href="#new">
        <v-icon left>mdi-account-plus</v-icon> Add Representative
      </v-tab>

      <!-- Representatives List -->
      <v-tab-item id="list">
        <v-card>
          <!-- Show representatives -->
          <v-data-iterator 
            v-if="representatives && representatives.length > 0"
            :items="representatives"
            >
            <template v-slot:default="props">           
              <div v-for="item in props.items" :key="item.name" class="pd-10">
                <RepresentativeCard :data="item" :search="search" />
              </div>
            </template>
          </v-data-iterator> 

          <div v-else class="fs-16 pd-20">
            No representative(s)
          </div>
        </v-card>
      </v-tab-item>

      <!-- Add Representative Form -->
      <v-tab-item id="new">
        <v-card>
          <v-form ref="form" style="padding: 20px;">
            <v-text-field v-model="form.name"
              :rules="[rules.present, rules.maxlength(50)]"
              color="vct-blue"
              counter="50"
              filled
              placeholder="Name"
            ></v-text-field>

            <v-text-field v-model="form.email"
              :rules="[rules.present, rules.maxlength(100), rules.email]"
              color="vct-blue"
              counter="100"
              filled
              placeholder="Email"
            ></v-text-field>

            <v-text-field v-model="form.phone"
              :rules="[rules.present, rules.maxlength(16), rules.phone]"
              color="vct-blue"
              counter="16"
              filled
              placeholder="Phone Number"
            ></v-text-field>

            <v-text-field v-model="form.city"
              :rules="[rules.present, rules.maxlength(50)]"
              color="vct-blue"
              counter="50"
              filled
              placeholder="City"
            ></v-text-field>

            <v-text-field v-model="form.country"
              :rules="[rules.present, rules.maxlength(50)]"
              color="vct-blue"
              counter="50"
              filled
              placeholder="Country"
            ></v-text-field>
          </v-form>

          <v-card-actions v-if="isOrgAdmin">
            <v-spacer></v-spacer>

            <v-btn
              @click="add()"
              :loading="loading"
              color="vct-blue"
              small
              >

              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app'

// mixins
import employer from '@/mixins/employer.js'
import firestore from '@/mixins/firestore.js'
import forms from '@/mixins/forms.js'
import helpers from '@/mixins/helpers.js'
import user from '@/mixins/user.js'

// components
import RepresentativeCard from '@/components/employer/representatives/RepresentativeCard'

// view
export default {
  mixins: [employer, firestore, forms, helpers, user],

  data () {
    return {
      representatives: null,

      headers: [
        {
          sortable: true,
          text: 'Name',
          value: 'name'
        },
        {
          sortable: false,
          text: 'Location',
          value: 'location'
        },
        {
          sortable: false,
          text: 'Status',
          value: 'status'
        },
        {
          sortable: false,
          text: 'Actions',
          value: 'action'
        }
      ],

      search: '',

      form: {
        city: null,
        country: null,
        email: null,
        name: null,
        phone: null
      }
    }
  },

  methods: {
    /*
     * Add a new representative to the account
     */
    async add () {
      if (this.$refs.form.validate()) {
        var doc = {
          'org-id': this.orgId,
          country: this.form.country,
          city: this.form.city,
          email: this.form.email,
          name: this.form.name,
          phone: this.form.phone,
          secret: this.uuidv4(),
          timestamp: new Date()
        }

        await this.fsSet('representatives', this.form.email.toLowerCase(), doc)
      }
    }
  },

  /*
   *
   */
  firestore () {
    return {
      representatives: firebase.firestore()
        .collection('representatives')
        .where('org-id', '==', this.orgId)
    }
  },

  components: {
    RepresentativeCard
  }
}
</script>

<style>

</style>


