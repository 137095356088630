<template>
  <v-app>
    <!-- Application layout and views -->
    <component :is="layout" v-if="allowed && !emailVerificationRequired">
      <router-view />
    </component>

    <!-- Email Verification is required -->
    <EmailVerification v-else-if="emailVerificationRequired" />

    <!-- Loading Bar -->
    <v-progress-linear v-else color="vct-blue" indeterminate></v-progress-linear>

    <!-- Snackbar -->
    <v-snackbar
      :value="snackbar"
      :color="snackbarColor"
      :multi-line="true"
      :timeout="snackbarTimeout"
      >

      {{ snackbarText }}

      <v-btn @click="closeSnackbar" text>
        Close
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app'

// css
import '@/assets/css/employer.css'
import '@/assets/css/freelancer.css'
import '@/assets/css/helpers.css'
import '@/assets/css/public.css'
import '@/assets/css/theme.css'

// mixins
import user from '@/mixins/user.js'

// store
import userStore from '@/stores/user.js'
import ui from '@/stores/ui.js'

// components
import EmailVerification from '@/components/EmailVerification'

// view
export default {
  mixins: [user],

  computed: {
    /*
     * Returns TRUE if the page is public or the user is authenticated into
     * the portal
     */
    allowed () {
      if (this.$route.meta.public) {
        return true
      }

      if (this.layout === 'freelancer-layout' 
        && this.uid) {

        return true
      }

      if (this.layout === 'employer-layout'
        && this.uid
        && this.orgId) {
        
        return true
      }

      return false
    },

    /*
     * Returns true of the page is public
     */
    emailVerificationRequired () {
      return this.uid
        && !this.emailVerified
        && !this.publicPage
    },

    /*
     * Computes the 'layout' that the view is meant to use
     */
    layout () {
      if (this.$route.meta.layout) {
        return this.$route.meta.layout
      }

      if (this.$route.fullPath.includes("/employer/")) {
        return 'employer-layout'
      }

      if (this.$route.fullPath.includes("/freelancer/")) {
        return 'freelancer-layout'
      }

      return 'plain-layout'
    },

    /*
     * Returns TRUE/FALSE on whether the page is a public view
     */
    publicPage () {
      return this.$route.meta.public !== undefined
    },

    /*
     * Snackbar
     */
    snackbar () {
      return ui.getters.snackbarStatus
    },

    snackbarText () {
      return ui.getters.snackbarText
    },

    snackbarColor () {
      return ui.getters.snackbarColor
    },

    snackbarTimeout () {
      return ui.getters.snackbarTimeout
    }
  },

  /*
   * Authentication
   */
  async beforeCreate () {
    await firebase.auth()
      .onAuthStateChanged(async (u) => {
        if (u) {
          /*
           * Set the user's variables
           */
          await userStore.commit('setEmail', u.email)
          await userStore.commit('setEmailVerified', u.emailVerified)
          await userStore.commit('setUid', u.uid)
          
          await userStore.dispatch('bindAccountSettings')

          /*
           * Retrieve backend information
           */
          await ui.dispatch('retrieveExercises')
        }
    })
  },

  methods: {
    /*
     * Close the UI snackbar
     */
    closeSnackbar () {
      ui.commit('closeSnackbar')
    }
  },

  components: {
    EmailVerification
  }
}
</script>