<template>
  <div class="app-page mx-auto">
    <v-card>
      <v-card-title>
        <div class="title font-weight-light" style="width: 100%;">
          Account Information
        </div>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <PersonalContactInformationForm />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PersonalContactInformationForm from '@/components/employer/PersonalContactInformationForm'

export default {
  components: {
    PersonalContactInformationForm
  }
}
</script>

<style>

</style>