<template>           
  <v-card :hover="true" color="blue lighten-5">
    <v-card-title class="bold fs-16">
      {{ data.name }}

      <v-spacer></v-spacer>

      <v-btn v-if="isOrgAdmin"
        @click="deleteRep()"
        :loading="loading"
        color="orange"
        small
        text
        >
        Remove
      </v-btn>
    </v-card-title>

    <v-divider />

    <v-card-text class="fs-16">
      <v-layout row wrap>
        <v-flex xs12 sm6 class="pd-10">
          <div class="mb-5">
            <v-icon class="mr-2" small>mdi-email</v-icon> {{ data.email }} 
          </div>

          <v-icon class="mr-2" small>mdi-phone</v-icon> {{ data.phone }}
        </v-flex>

        <v-flex xs12 sm6 class="pd-10">
          <div class="mb-5">
            <v-icon class="mr-2" small>mdi-home-city</v-icon> {{ data.city }} 
          </div>

          <v-icon class="mr-2" small>mdi-city</v-icon> {{ data.country }}
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import employer from '@/mixins/employer.js'
import firestore from '@/mixins/firestore.js'

export default {
  props: ['data'],
  mixins: [employer, firestore],

  methods: {
    /*
     *
     */
    deleteRep () {
      window.console.log(this.data.id)

      return this.fsDelete('representatives', this.data.id)
    }
  }
}
</script>

<style>

</style>