import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// routes
import publicRoutes from './public.js'
import employerRoutes from './employer.js'

const routes = []
  .concat(employerRoutes)
  .concat(publicRoutes)

export default new Router({
  routes: routes
})