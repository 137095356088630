import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('div',{staticClass:"pd-10"},[_c(VCard,{staticClass:"blue lighten-5",attrs:{"hover":true}},[_c(VCardTitle,[_c('div',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.data.label)+" ")]),_c(VSpacer),(!_vm.noaction)?_c(VBtn,{attrs:{"color":"blue","small":"","text":""},on:{"click":function($event){return _vm.archive(_vm.data.id, _vm.data.archived)}}},[_vm._v(" "+_vm._s((!_vm.data.archived) ? 'Archive' : 'Take')+" ")]):_vm._e()],1),_c(VCardText,{staticClass:"black--text"},[_vm._v(" "+_vm._s(_vm.data.description || '')+" "),_c('ul',{staticClass:"vct-blue-bullets mt-3"},_vm._l((_vm.data.exercises),function(uuid,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(_vm.exerciseTitle(uuid))+" ")])}),0)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }