<template>
  <div>
    <v-form ref="form">
      <div class="mb-20">
        User ID: {{ uid }}
      </div>

      <v-text-field v-model="name"
        :rules="[rules.present, rules.maxlength(100)]"
        color="vct-blue"
        counter="100"
        filled
        placeholder="Full Name"
        required
      ></v-text-field>

      <v-text-field v-model="title"
        :rules="[rules.present, rules.maxlength(100)]"
        color="vct-blue"
        counter="100"
        filled
        placeholder="Job Title"
        required
      ></v-text-field>

      <v-text-field v-model="phone"
        :rules="[rules.present, rules.maxlength(16), rules.phone]"
        color="vct-blue"
        counter="16"
        filled
        placeholder="Phone Number"
        required
      ></v-text-field>

      <v-text-field v-model="secondaryemail"
        :rules="[rules.present, rules.maxlength(100), rules.email]"
        color="vct-blue"
        counter="100"
        filled
        placeholder="Secondary Email"
      ></v-text-field>
    </v-form>

    <v-btn @click="save()"
      :loading="loading"
      color="vct-blue" 
      small
      >

      Save
    </v-btn>
  </div>
</template>

<script>
// mixins
import firestore from '@/mixins/firestore.js'
import forms from '@/mixins/forms.js'
import utils from '@/mixins/utils.js'
import user from '@/mixins/user.js'

export default {
  mixins: [firestore, forms, utils, user],

  data () {
    return {
      name: null,
      phone: null,
      secondaryemail: null,
      title: null
    }
  },

  methods: {
    /*
     * Update firestore
     */
    async save () {
      if (this.$refs.form.validate()) {
        var doc = {
          'org-id': this.orgId,
          name: this.name,
          phone: this.phone,
          roles: Object.values(this.roles),
          secondaryemail: this.secondaryemail,
          title: this.title
        }

        await this.fsUpdate('account-settings', this.uid, doc)
      }
    }
  },

  mounted () {
    this.safeCopyObjects(this.settings, this.$data)
  }
}
</script>

<style>

</style>