import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/routes.js'

import firebase from 'firebase/compat/app'
import 'firebase/compat/functions'

import { firestorePlugin } from 'vuefire'

// Layouts
import EmployerLayout from '@/layouts/EmployerLayout'
import PlainLayout from '@/layouts/PlainLayout'

Vue.component('employer-layout', EmployerLayout)
Vue.component('plain-layout', PlainLayout)

//
Vue.use(firestorePlugin)

// Firebase configuration 
const firebaseConfig = {
  apiKey: "AIzaSyCsItL0Bwo18K20fuIWu-C6GadFHEjkygQ",
  authDomain: "cyber-talent-portal.firebaseapp.com",
  databaseURL: "https://cyber-talent-portal.firebaseio.com",
  projectId: "cyber-talent-portal",
  storageBucket: "cyber-talent-portal.appspot.com",
  messagingSenderId: "752198557862",
  appId: "1:752198557862:web:9b18d2bfa3b890b4581467"
}

// Initialise Firebase
firebase.initializeApp(firebaseConfig)

// debugging
if (window.location.hostname === 'localhost') {
  firebase.functions().useFunctionsEmulator('http://localhost:5001')
}

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
