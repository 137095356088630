import { vuexfireMutations, firestoreAction } from 'vuexfire'

import firebase from 'firebase/compat/app'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    email: null,
    emailVerified: false,
    loading: false,
    roles: null,
    settings: null,
    uid: null
  },

  getters: {
    accountType: state => {
      return state.accountType
    },

    email: state => {
      return state.email
    },

    emailVerified: state => {
      return state.emailVerified
    },

    loading: state => {
      return state.loading
    },

    orgId: state => {
      return state.orgId
    },

    settings: state => {
      return state.settings
    },

    uid: state => {
      return state.uid
    }
  },

  actions: {
    /*
     * Binds `settings`
     */
    bindAccountSettings: firestoreAction(({ state, bindFirestoreRef }) => {
      return bindFirestoreRef('settings', firebase
        .firestore()
        .collection('account-settings')
        .doc(state.uid)
      )
    })
  },

  mutations: {
    /*
     * Resets state variables to null
     */
    reset (state) {
      state.email = null
      state.orgID = null
      state.uid   = null
      state.roles = null
    },

    /*
     * Sets `email` field
     */
    setEmail (state, email) {
      state.email = email
    },

    /*
     * Sets the `emailVerified` field
     */
    setEmailVerified (state, val) {
      state.emailVerified = val
    },

    /*
     * Sets `uid` field
     */
    setUid (state, uid) {
      state.uid = uid
    },

    ...vuexfireMutations
  }
})