/* vue router paths for employer pages (authenticated) */

import AuditLogs from '@/views/employer/AuditLogs'
import Candidate from '@/views/employer/vetting/Candidate'
import NewPackage from '@/views/employer/vetting/NewPackage'
import Representatives from '@/views/employer/Representatives'
import UserProfile from '@/views/employer/UserProfile'
import Vetting from '@/views/employer/vetting/Layout'
import VettingInvite from '@/views/employer/vetting/New'

export default [
  {
    path: '/employer/audit-logs',
    component: AuditLogs
  },

  {
    path: '/employer/vetting/candidate/:docId',
    component: Candidate
  },

  {
    path: '/employer/representatives',
    component: Representatives
  },

  {
    path: '/employer/user-profile',
    component: UserProfile
  },

  {
    path: '/employer/vetting',
    component: Vetting
  },

  {
    path: '/employer/vetting/new',
    component: VettingInvite
  },

  {
    path: '/employer/vetting/new-package',
    component: NewPackage
  }
]