<template> 
  <div class="app-page elevation-1 mx-auto">
    <v-tabs color="vct-blue" slider-color="vct-blue">
      <v-tab>
        <v-icon left>mdi-account-group</v-icon> Candidates
      </v-tab>

      <v-tab>
        <v-icon left>mdi-format-list-bulleted</v-icon> Vetting Packages
      </v-tab>

      <v-tab v-if="passedCandidates">
        <v-icon left>mdi-archive</v-icon> Successful Candidates
      </v-tab>

      <v-tab v-if="archivedCandidates">
        <v-icon left>mdi-archive</v-icon> Archived Candidates
      </v-tab>

      <v-tab v-if="archivedPackages">
        <v-icon left>mdi-archive</v-icon> Archived Packages
      </v-tab>

      <!-- Candidates -->
      <v-tab-item>
        <Candidates :archived="false" />
      </v-tab-item>

      <!-- Packages -->
      <v-tab-item>
        <Packages :archived="false" />
      </v-tab-item>

      <!-- Successful Candidates -->
      <v-tab-item v-if="passedCandidates">
        <Candidates :successful="true" />
      </v-tab-item>

      <!-- Archived Candidates -->
      <v-tab-item v-if="archivedCandidates">
        <Candidates :archived="true" />
      </v-tab-item>

      <!-- Archived Vetting Packages -->
      <v-tab-item v-if="archivedPackages">
        <Packages :archived="true" />
      </v-tab-item>
    </v-tabs>
  </div>  
</template>

<script>
// mixins
import employer from '@/mixins/employer.js'

// components
import Candidates from '@/components/employer/vetting/Candidates'
import Packages from '@/components/employer/vetting/Packages'

// view
export default {
  mixins: [employer],

  computed: {
    archivedCandidates () {
      return this.candidates.filter(c => c.archived && !c.pass).length > 0
    },

    archivedPackages () {
      return this.packages.filter(p => p.archived === true).length > 0
    },

    passedCandidates () {
      return this.candidates.filter(c => c.pass === true).length > 0
    }
  },

  components: {
    Candidates,
    Packages
  }
}
</script>