<template>
  <v-navigation-drawer :value="this.opened" :width="220" app>
    <!-- logo -->
    <img class="sidenav-logo" src="@/assets/logo-sidenav.png" />

    <v-divider />

    <!-- eslint-disable -->
    <v-list dense>
      <v-list-item-group color="vct-blue">
        <v-list-item to="/employer/vetting">
          <v-list-item-icon>
            <v-icon>mdi-playlist-check</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Vetting
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <!-- Account -->
      <v-subheader>       
        Account
      </v-subheader>

      <v-list-item-group color="vct-blue">
        <v-list-item to="/employer/user-profile">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Account Information
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/employer/representatives">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Representatives
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/employer/audit-logs">
          <v-list-item-icon>
            <v-icon>mdi-file-settings</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Audit Logs
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <!-- Actions -->
      <v-subheader>       
        Actions
      </v-subheader>

      <v-list-item-group color="vct-blue">
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// mixins
import portal from '@/mixins/portal.js'
import user from '@/mixins/user.js'

export default {
  props: ['opened'],
  mixins: [portal, user]
}
</script>

<style>
.logo-small {
  margin-right: -40px;
  max-width: 180px;
}
</style>
