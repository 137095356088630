<template>
  <v-container fluid class="registration-page">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <!-- logo -->
        <img class="mb-50" src="@/assets/logo.png" style="width: 100%" />

        <!-- Header -->
        <v-toolbar color="vct-blue">
          <v-toolbar-title class="centered card-header">
            Register Account
          </v-toolbar-title>
        </v-toolbar>

        <AccountForm formType="registration" />

        <!-- Alternative Options -->
        <div class="account-links">
          Already registered? 
          <router-link to="/">
            Login
          </router-link>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AccountForm from '@/components/public/AccountForm'

export default {
  components: {
    AccountForm
  }
}
</script>

<style>

</style>
