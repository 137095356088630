<template>
  <div class="app-page mx-auto">
    <v-stepper v-model="step">
      <!-- Header -->
      <v-stepper-header>
        <v-stepper-step 
          :complete="step > 1"
          :editable="true"
          color="vct-blue"
          step="1"
          >

          Select Package
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step 
          :complete="step > 2"
          :editable="true"
          color="vct-blue"
          step="2"
          >

          Enter Email(s)
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step 
          :complete="step > 3"
          color="vct-blue"
          step="3"
          >

          Validate
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- Select Package -->
        <v-stepper-content step="1">
          <v-text-field v-model="search"
            append-icon="mdi-database-search"
            color="vct-blue"
            hide-details
            label="Search"
            single-line
            style="margin-bottom: 10px;"
          ></v-text-field>

          <v-data-iterator v-if="packages && packages.length > 0" :items="packages.filter(p => p.archived === false)">
            <!-- Package Card Component -->
            <template v-slot:default="props">           
              <div v-for="(item, index) in props.items" :key="index"
                @click="selectPackage(item.id)"
                class="pd-10" 
                >
                <Package 
                  :data="item"
                  :noaction="true"
                  :search="search"
                ></Package>
              </div>
            </template>
          </v-data-iterator>

          <v-progress-linear v-else color="vct-blue" indeterminate></v-progress-linear>
        </v-stepper-content>

        <!-- Email addresses -->
        <v-stepper-content step="2">
          <b>
            Enter the email addresses of the candidates that you wish to vet:
          </b>

          <v-textarea v-model="textarea"
            :rules="[rules.present]"
            class="mt-5"
            color="vct-blue"
            counter="1500"
            filled
            placeholder="Email address(es)"
            required
          >
          </v-textarea>

          <p class="fs-12">
            Enter one email address per line. {{ emails.length }} addresses entered.
          </p>

          <v-btn 
            @click="increment()"
            :disabled="emails.length === 0"
            color="vct-blue" 
            small
            >
            Next
          </v-btn>
        </v-stepper-content>

        <!-- Checkout -->
        <v-stepper-content v-if="selectedPackage" step="3">
          <h3>
            Vetting Package:
          </h3>

          <Package
            :data="selectedPackage"
            :noaction="true"
            search=""
          ></Package>

          <h3 class="mt-3">
            Candidates:
          </h3>

          <ul class="vct-blue-bullets mt-3">
            <li v-for="(email, index) in emails" :key="index" class="mb-1">
              {{ email }}
            </li>
          </ul>

          <v-btn 
            @click="save()"
            :loading="loading"
            class="mt-3"
            color="vct-blue"
            small
            >

            Go!
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
// mixins
import cart from '@/mixins/cart.js'
import employer from '@/mixins/employer.js'
import forms from '@/mixins/forms.js'
import firestore from '@/mixins/firestore.js'
import stepper from '@/mixins/stepper.js'
import ui from '@/mixins/ui.js'
import user from '@/mixins/user.js'
import utils from '@/mixins/utils.js'

// components
import Package from '@/components/employer/vetting/Package'

// view
export default {
  mixins: [cart, employer, firestore, forms, stepper, ui, user, utils],

  data () {
    return {
      package: null,
      search: '',
      textarea: ''
    }
  },

  computed: {
    /*
     * Returns an array of emails that the user has selected
     */
    emails () {
      return this.textarea
        .split("\n")
        .filter(email => /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
        .filter(this.onlyUnique)
    },

    /*
     * Returns an array of object to pass to Snipcart
     */
    metadata () {
      var items = []

      for(var i = 0; i < this.emails.length; i++) {
        var email = this.emails[i]

        items.push({
          'org-id': this.orgId,
          email: email.toLowerCase(),
          from: this.email,
          package: this.package,
          uid: this.uid
        })
      }

      return items
    },

    /*
     * Returns the vetting package that the user has selected
     */
    selectedPackage () {
      return this.packages.filter(p => p.id === this.package)[0] || null
    }
  },

  methods: {
    /*
     * Administrator function: create new candidates without having to checkout/pay
     */
    async save () {
      var i = 0

      for(var j = 0; j < this.emails.length; j++) {
        var email = this.emails[j]

        var doc = {
          'org-id': this.orgId,
          archived: false,
          email: email.toLowerCase(),
          fail: false,
          from: this.email,
          package: this.package,
          pass: false,
          process: {
            'opened-email': false,
            'accepted-challenge': false,
            'submitted-exercises': false
          },
          timestamp: new Date(),
          unlocked: false
        }

        if (await this.fsCreate('candidates', doc)) {
          i++
        }
      }

      if (i === this.emails.length) {
        this.$router.push('/employer/vetting')
      }
    },

    /*
     * Select a given package
     */
    selectPackage (id) {
      this.package = id
      this.increment()
    }
  },

  /*
   *
   */
  mounted () {
    this.snipcart().events.on('cart.confirmed', () => {
      this.$router.push('/employer/vetting')
    })
  },

  components: {
    Package
  }
}
</script>

<style>

</style>