import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false
  },

  icons: {
    iconfont: 'mdi'
  }
})