<template>
  <v-container fluid class="authentication-page">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <!-- View Header -->
        <v-toolbar color="vct-blue">
          <v-toolbar-title class="centered card-header">
            Please Verify Your Email
          </v-toolbar-title>
        </v-toolbar>

        <!-- Card -->
        <v-card tile>
          <v-card-text>
            <p>
              For security reasons, please verify your email address:
            </p>

            <v-text-field 
              :disabled="true"
              :value="email"
              color="vct-blue"
              filled
            ></v-text-field>

            <v-btn @click="send()" :loading="loading" color="vct-blue" style="width: 100%;">
              Send Confirmation Email
            </v-btn>
          </v-card-text>
        </v-card>

        <!-- Register / Authenticate -->
        <div class="account-links">
          <a @click="logout()">
            Try another account
          </a>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import firebase from 'firebase/compat/app'

// mixins
import portal from '@/mixins/portal.js'
import ui from '@/mixins/ui.js'
import user from '@/mixins/user.js'

// view
export default {
  mixins: [portal, ui, user],

  data () {
    return {
      loading: false
    }
  },

  methods: {
    /*
     *
     */
    async send () {
      this.loading = true

      var user = firebase.auth().currentUser

      await user.sendEmailVerification()
        .then(() => {
          this.throwSuccess('Confirmation email sent!')
        })
        .catch(error => {
          this.throwError(error)
        })

      this.loading = false
    }
  }
}
</script>

<style>

</style>