import employer from '@/stores/employer.js'
import ui from '@/mixins/ui.js'
import user from '@/mixins/user.js'
import utils from '@/mixins/utils.js'

export default {
  mixins: [ui, utils, user],

  computed: {
    /*
     * Returns firestore packages
     */
    candidates () {
      return employer.getters.candidates
    },

    /*
     * Returns TRUE if the user is the organisation's administrator
     */
    isOrgAdmin () {
      return this.organisation 
        && this.organisation.administrator === this.email
    },

    /*
     * Returns firestore jobs
     */
    jobs () {
      return employer.getters.jobs
    },

    /*
     * Returns firestore packages
     */
    packages () {
      return employer.getters.packages
    },

    /*
     * Returns the employer's organisation firestore object
     */
    organisation () {
      return employer.getters.organisation
    },

    /*
     * Returns TRUE if the organisation is setup
     */
    organisationSetup () {
      return this.objHasNoNull(this.organisation)
        && this.objHasNoNull(this.settings)
        && Object.keys(this.settings).length > 3
    }
  },

  methods: {
    /*
     * Retrieves the details for a particular package
     */
    pck (id) {
      var pck = this.packages.filter(p => p.id === id)

      if (!pck || pck.length === 0) {
        return null
      }

      pck = Object.assign({}, pck[0])
      pck.exercises = this.getExercises(pck.exercises)
      
      return pck
    },

    /*
     * Returns an array of the categories that the package has exercises for
     */
    pckCategories (pck) {
      if (!pck.exercises) {
        return []
      }

      return pck.exercises.map(e => e.groupUID).filter(this.onlyUnique)
    }
  }
}