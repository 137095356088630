import ui from '@/stores/ui.js'

export default {
  computed: {
    /*
     * Returns the list of exercise categories
     */
    exerciseCategories () {
      var categories = []

      for(var i = 0; i < this.exercises.length; i++) {
        var exercise = this.exercises[i]

        if (!categories.includes(exercise.groupUID)) {
          categories.push(exercise.groupUID)
        }
      }

      return categories.sort()
    },

    /*
     * Returns the list of exercises
     */
    exercises () {
      return ui.getters.exercises
    },

    /*
     * Returns TRUE if we have downloaded all the information we need to display
     * the portal
     */
    uiSetup () {
      return this.exercises && this.exercises.length > 0
    }
  },

  methods: {
    /*
     * Counts how many exercises are in a category
     */
    countExercises (category) {
      return this.exercises.filter(e => e.groupUID === category).length
    },

    /*
     * Returns how much effort we anticipate this exercise would take a candidate
     */
    estimateEffort (exercise) {
      if (!exercise || !exercise.difficulty || typeof exercise.difficulty !== 'string') {
        return 0
      }

      var difficulty = exercise.difficulty.toLowerCase()

      if (difficulty === "concepts") {
        return '30 min'
      }

      if (difficulty === "novice") {
        return '30 min'
      }

      if (difficulty === "advanced beginner") {
        return '1-3 hours'
      }

      if (difficulty === "competent") {
        return '3-6 hours'
      }

      if (difficulty === "proficient") {
        return '1 day'
      }

      return '3 days'
    },

    /*
     * Retrieves the label of an exercise
     */
    exerciseTitle (uuid) {
      var exercise = this.exercises.filter(e => e.uuid === uuid)

      if (exercise.length > 0) {
        return exercise[0].title
      }
    },

    /*
     * Extract the first letters of two words to create an Vuetify avatar.
     *
     * Example: John Smith -> JS
     */
    extractAvatarLetters (text) {
      text = text.match(/\b(\w)/g).slice(0, 2)
      text = text.join('')

      return text
    },

    /*
     * Turns a string like "specialist-bundle-metasploit" into "Specialist Bundle Metasploit"
     */
    formatText (text) {
      if (text.toLowerCase() === 'grc') {
        return 'GRC'
      }
      
      return text
        .split('-').join(' ')
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        })
    },

    /*
     * Returns an exercise based on its uuid
     */
    getExercise (uuid) {
      var exercise = this.exercises.filter(e => e.uuid === uuid)

      if (!exercise || exercise.length === 0) {
        return null
      }

      return exercise[0]
    },

    /*
     * Takes an array of uuids and returns the exercises that match
     */
    getExercises (_array) {
      return this.exercises.filter(e => _array.includes(e.uuid))
    },

    /*
     * Converts an exercise difficulty label to an integer for ranking purposes
     */
    rankExercise (exercise) {
      if (!exercise || !exercise.difficulty || typeof exercise.difficulty !== 'string') {
        return 0
      }

      var difficulty = exercise.difficulty.toLowerCase()

      if (difficulty === "concepts") {
        return 1
      }

      if (difficulty === "novice") {
        return 2
      }

      if (difficulty === "advanced beginner") {
        return 3
      }

      if (difficulty === "competent") {
        return 4
      }

      if (difficulty === "proficient") {
        return 5
      }

      return 6
    },

    /*
     * Sort an array of exercise objects by difficulty.
     *
     * e.g. concept, novice, advanced beginner, competent etc.
     */
    sortExercises (a, b) {
      return this.rankExercise(a) - this.rankExercise(b)
    },

    /*
     * Displays the error snackbar
     */
    throwError (error) {
      ui.commit('triggerErrorSnackbar', error)
    },

    /*
     * Displays the success snackbar
     */
    throwSuccess (message) {
      ui.commit('triggerSuccessSnackbar', message)
    }
  }
}