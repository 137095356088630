<template>
  <div v-if="loaded" class="pd-10">        
    <v-card
      :hover="true"
      class="blue lighten-5"
      :to="`/employer/vetting/candidate/${data.id}`"
    >
      <v-card-title>
        <v-avatar :color="statusColour" size="15px">
          <v-icon dark>mdi-account-circle</v-icon>
        </v-avatar>

        <span class="title font-weight-light" style="padding-left:10px;">
          {{ data.email }}
        </span>

        <v-spacer></v-spacer>

        <span class="font-weight-bold title uppercase" :style="`color: ${statusColour}`">
          {{ statusText }}
        </span>
      </v-card-title>

      <v-card-text>
        <div class="fs-16 mt-5">
          <b>Vetting Package:</b> {{ _package.label }}<br />
        </div>

        <div class="fs-16 mt-5">
          <b>Invited:</b> {{ data.timestamp.toDate().toLocaleString() }}
        </div>
        
        <!-- skills begin assessed -->
        <v-chip-group 
          active-class="deep-purple accent-4 white--text"
          class="mt-20"
          column
        >
          <v-chip v-for="(skill, index) in categories" :key="index" class="fs-12">
            {{ formatText(skill) }}
          </v-chip>
        </v-chip-group>
      </v-card-text>

      <!-- bottom card information -->
      <v-card-actions style="padding-top: 0 !important;">
        <CandidateStepper :data="data" />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// mixins
import employer from '@/mixins/employer.js'
import ui from '@/mixins/ui.js'
import vetting from '@/mixins/vetting.js'

// view
export default {
  mixins: [employer, ui, vetting],

  props: ['search', 'data'],

  computed: {
    /*
     * Returns TRUE if the details have been loaded
     */
    loaded () {
      return this.data.email.includes(this.search) 
        && this._package
        && this.categories.length > 0
    }
  }
}
</script>

<style>

</style>