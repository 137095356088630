import firebase from 'firebase/compat/app'

// stores
import user from '@/stores/user.js'

// class
export default {
  computed: {
    /*
     * Generic variable to retrieve the `docId` in a URL route
     */
    docId () {
      return this.$route.params.docId || null
    }
  },

  methods: {
    /*
     * Logout function
     */
    logout () {
      firebase.auth()
        .signOut()
        .then(async () => {
          await user.commit('reset')
          
          this.$router.push('/')
        })
    }
  }
}