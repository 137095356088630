import CandidateStepper from '@/components/employer/vetting/CandidateStepper'
import employer from '@/mixins/employer.js'

export default {
  mixins: [employer],

  computed: {
    /*
     * Returns the exercise categories for the package
     */
    categories () {
      return this.pckCategories(this._package)
    },

    /*
     * Returns the package that was used for the candidate
     */
    _package () {
      return this.pck(this.data.package)
    },

    /*
     * Compute the css color of the candidate's vetting status
     */
    statusColour () {
      if (this.data.pass) {
        return '#198f3d'
      }
      
      if (this.data.fail) {
        return 'red'
      }

      return 'orange'
    },

    /*
     * Compute the text of the vetting status
     */
    statusText () {
      if (this.data.pass) {
        return 'PASS'
      }
      
      if (this.data.fail) {
        return 'FAIL'
      }

      return 'IN PROGRESS'
    }
  },

  components: {
    CandidateStepper
  }
}