import { vuexfireMutations, firestoreAction } from 'vuexfire'
import firebase from 'firebase/compat/app'
import user from '@/stores/user.js'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    candidates: null,
    organisation: null,
    packages: null
  },

  getters: {
    candidates: state => {
      return state.candidates
    },

    organisation: state => {
      return state.organisation
    },

    packages: state => {
      return state.packages
    }
  },

  mutations: vuexfireMutations,

  actions: {
    /*
     * Binds `candidates`
     */
    bindCandidates: firestoreAction(context => {
      return context.bindFirestoreRef('candidates', firebase
        .firestore()
        .collection('candidates')
        .where('org-id', '==', user.getters.settings['org-id'])
        .orderBy('timestamp', 'desc')
      )
    }),

    /*
     * Binds the `organisation` firestore document
     */
    bindOrganisationDoc: firestoreAction(context => {
      return context.bindFirestoreRef('organisation', firebase
        .firestore()
        .collection('organisations')
        .doc(user.getters.settings['org-id'])
      )
    }),

    /*
     * Binds organisation `vetting-packages`
     */
    bindPackages: firestoreAction(context => {
      return context.bindFirestoreRef('packages', firebase
        .firestore()
        .collection('vetting-packages')
        .where('org-id', '==', user.getters.settings['org-id'])
        .orderBy('label', 'asc')
      )
    })
  }
})